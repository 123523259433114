/* eslint-disable no-underscore-dangle */
import { RecruitmentPlanType, RecruitmentType } from 'shared/constants/recruitment-stage-definitions';

export const MOBILE_CATEGORY_PREFIX = 'mobile';
export const WEB_CATEGORY_PREFIX = 'web';

const BANNER_EVENT_CATEGORY = 'banner';
const SUB_BANNER_EVENT_CATEGORY = 'subBanner';
const CTA_BANNER_EVENT_CATEGORY = 'ctaBanner';
const NAV_EVENT_CATEGORY = 'Nav';
const FOOTER_EVENT_CATEGORY = 'Footer';

const MY_CENTER_CATEGORY = 'My Center';
const MAIN_CATEGORY = 'main';
export const JOB_LIST_CATEGORY = 'jobList';
const ABOUT_CATEGORY = 'about';
const RECRUITMENT_CATEGORY = 'recruitment';
const SUPER_X_CATEGORY = 'SuperX';
export const PROFILE_CATEGORY = 'profile';

const LOGIN_MODAL_CATEGORY = 'Login_Modal';
const APPLIED_WARNING_MODAL_CATEGORY = 'AppliedWarning_Modal';

export const APPLICATION_CATEGORY = 'application';
export const APPLICATION_SUCCESS_CATEGORY = 'success';

export const matomoEventTypeMap = {
  nav: {
    logo: 'NAV_HOME_LOGO',
    home: 'NAV_HOME',
    joinUs: 'NAV_RECRUITMENT',
    superX: 'NAV_NON_IT',
    training: 'NAV_TRAINING',
    notice: 'NAV_NOTICE',
    faq: 'NAV_FAQ',
    aboutUs: 'NAV_ABOUT',
    login: 'NAV_LOGIN',
    logout: 'NAV_LOGOUT',
    admin: 'NAV_ADMIN',
    myCenter: 'NAV_MY_CENTER',
    menuIconOpen: 'NAV_MENU_ICON_OPEN',
    menuIconClose: 'NAV_MENU_ICON_CLOSE',
  },
  weChatLogin: {
    loginIcon: 'WECHAT_LOGIN_MODAL_ICON',
    policy: 'WECHAT_LOGIN_MODAL_POLICY',
    policyLink: 'WECHAT_LOGIN_MODAL_POLICY_LINK',
    login: 'WECHAT_LOGIN',
  },
  main: {
    aboutUs: 'BANNER_ABOUT_US',
    notice: 'MAIN_NOTICES',
    recruitment: 'SUB_BANNER_RECRUITMENT',
    internship: 'SUB_BANNER_INTERNSHIP',
    superX: 'SUB_BANNER_SUPER_X',
    superXLink: 'SUPER_X_LINK',
    viewNow: 'SUB_BANNER_VIEW_NOW',
  },
  recruitment: {
    [`TAB_${RecruitmentType.GRAD}`]: 'TAB_GRADUATE',
    [`TAB_${RecruitmentType.INTERN}`]: 'TAB_INTERNSHIP',
    faq: 'RECRUITMENT_FQA_LINK',
  },
  jobList: {
    back: 'JOB_LIST_BACK_NAV',
    jobLink: 'JOB_LINK',
    hotCity: 'JOB_HOT_CITY',
  },
  superX: {
    apply: 'SUPER_X_BANNER_LINK',
    showMore: 'SUPER_X_STORY_WALL_SHOW_MORE',
    close: 'SUPER_X_STORY_WALL_SHOW_CLOSE',
  },
  application: {
    back: 'APPLICATION_BACK_NAV',
    applyNow: 'APPLICATION_APPLY_NOW',
    apply: 'APPLICATION_APPLY',
    attachment: 'APPLICATION_ATTACHMENT',
    uploadAttachment: 'APPLICATION_ATTACHMENT_UPLOAD',
    cancelUploadAttachment: 'APPLICATION_ATTACHMENT_UPLOAD_CANCEL',
  },
  footer: {
    joinUs: 'FOOTER_RECRUITMENT',
    superX: 'FOOTER_NON_IT',
    training: 'FOOTER_TRAINING',
    notice: 'FOOTER_NOTICE',
    faq: 'FOOTER_FAQ',
    aboutUs: 'FOOTER_ABOUT',
    thoughtWorksWeb: 'FOOTER_TW_WEB',
    thoughtWorksInsight: 'FOOTER_INSIGHT',
    thoughtWorksPolicy: 'FOOTER_POLICY',
    thoughtWorksCodeOfConduct: 'FOOTER_CODE_OF_CONDUCT:',
    emailsTo: 'FOOTER_EMAILS_TO',
  },
  success: {
    myCenter: 'SUCCESS_MY_CENTER',
  },
  account: {
    gradLink: 'ACCOUNT_GRAD_LINK',
    internshipLink: 'ACCOUNT_INTERNSHIP_LINK',
  },
  appliedWarningModal: {
    myCenter: 'APPLIED_WARNING_MODAL_MY_CENTER',
  },
  about: {
    globalImpact: {
      back: 'GLOBAL_IMPACT_BACK_NAV',
    },
    graduates: {
      back: 'GLOBAL_IMPACT_BACK_NAV',
    },
  },
};

const navEvent = {
  [matomoEventTypeMap.nav.logo]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_LOGO`,
  },
  [matomoEventTypeMap.nav.home]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_首页`,
  },
  [matomoEventTypeMap.nav.joinUs]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_加入我们`,
  },
  [matomoEventTypeMap.nav.superX]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_SuperX`,
  },
  [matomoEventTypeMap.nav.training]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_培训发展`,
  },
  [matomoEventTypeMap.nav.notice]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_信息公告`,
  },
  [matomoEventTypeMap.nav.faq]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_答疑专区`,
  },
  [matomoEventTypeMap.nav.aboutUs]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_关于我们`,
  },
  [matomoEventTypeMap.nav.login]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_登录`,
  },
  [matomoEventTypeMap.nav.logout]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_退出登录`,
  },
  [matomoEventTypeMap.nav.myCenter]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_${MY_CENTER_CATEGORY}`,
  },
  [matomoEventTypeMap.nav.admin]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_管理后台`,
  },
  [matomoEventTypeMap.nav.menuIconOpen]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_Menu icon_Open`,
  },
  [matomoEventTypeMap.nav.menuIconClose]: {
    eventCategory: NAV_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${NAV_EVENT_CATEGORY}_Menu icon_Close`,
  },
};
const wechatEvent = {
  [matomoEventTypeMap.weChatLogin.login]: {
    eventCategory: `Login`,
    eventAction: '微信登录',
    eventName: `微信登录`,
  },
  [matomoEventTypeMap.weChatLogin.loginIcon]: {
    eventCategory: `${LOGIN_MODAL_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${LOGIN_MODAL_CATEGORY}_Wechat Icon`,
  },
  [matomoEventTypeMap.weChatLogin.policy]: {
    eventCategory: `${LOGIN_MODAL_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${LOGIN_MODAL_CATEGORY}_Policy`,
  },
  [matomoEventTypeMap.weChatLogin.policyLink]: {
    eventCategory: `${LOGIN_MODAL_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${LOGIN_MODAL_CATEGORY}_Policy Link`,
  },
};
const mainPageEvent = {
  [matomoEventTypeMap.main.aboutUs]: {
    eventCategory: `${MAIN_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${MAIN_CATEGORY}_${BANNER_EVENT_CATEGORY}_关于我们`,
  },
  [matomoEventTypeMap.main.notice]: {
    eventCategory: `${MAIN_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${MAIN_CATEGORY}_滚动公告`,
  },
  [matomoEventTypeMap.main.recruitment]: {
    eventCategory: `${MAIN_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${MAIN_CATEGORY}_${SUB_BANNER_EVENT_CATEGORY}_校园招聘`,
  },
  [matomoEventTypeMap.main.superX]: {
    eventCategory: `${MAIN_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${MAIN_CATEGORY}_${SUB_BANNER_EVENT_CATEGORY}_superX探路者计划`,
  },
  [matomoEventTypeMap.main.internship]: {
    eventCategory: `${MAIN_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${MAIN_CATEGORY}_${SUB_BANNER_EVENT_CATEGORY}_实习生招聘`,
  },
  [matomoEventTypeMap.main.superXLink]: {
    eventCategory: `${MAIN_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${MAIN_CATEGORY}_superX探路者计划`,
  },
  [matomoEventTypeMap.main.viewNow]: {
    eventCategory: `${MAIN_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${MAIN_CATEGORY}_${SUB_BANNER_EVENT_CATEGORY}_立即浏览`,
  },
};
const recruitmentPageEvent = {
  [matomoEventTypeMap.recruitment[`TAB_${RecruitmentType.GRAD}`]]: {
    eventCategory: `${RECRUITMENT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${RECRUITMENT_CATEGORY}_tab_校园招聘`,
  },
  [matomoEventTypeMap.recruitment[`TAB_${RecruitmentType.INTERN}`]]: {
    eventCategory: `${RECRUITMENT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${RECRUITMENT_CATEGORY}_tab_实习生招聘`,
  },
  [matomoEventTypeMap.recruitment.faq]: {
    eventCategory: `${RECRUITMENT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${RECRUITMENT_CATEGORY}_常见问题`,
  },
  [`${RecruitmentType.GRAD}_${RecruitmentPlanType.DOMESTIC}`]: {
    eventCategory: `${RECRUITMENT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${RECRUITMENT_CATEGORY}_${RecruitmentType.GRAD}_${RecruitmentPlanType.DOMESTIC}`,
  },
  [`${RecruitmentType.GRAD}_${RecruitmentPlanType.OVERSEAS}`]: {
    eventCategory: `${RECRUITMENT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${RECRUITMENT_CATEGORY}_${RecruitmentType.GRAD}_${RecruitmentPlanType.OVERSEAS}`,
  },
  [`${RecruitmentType.GRAD}_${RecruitmentPlanType.DOMAIN}`]: {
    eventCategory: `${RECRUITMENT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${RECRUITMENT_CATEGORY}_${RecruitmentType.GRAD}_${RecruitmentPlanType.DOMAIN}`,
  },
  [`${RecruitmentType.INTERN}_${RecruitmentPlanType.DOMESTIC}`]: {
    eventCategory: `${RECRUITMENT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${RECRUITMENT_CATEGORY}_${RecruitmentType.INTERN}_${RecruitmentPlanType.DOMESTIC}`,
  },
  [`${RecruitmentType.INTERN}_${RecruitmentPlanType.OVERSEAS}`]: {
    eventCategory: `${RECRUITMENT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${RECRUITMENT_CATEGORY}_${RecruitmentType.INTERN}_${RecruitmentPlanType.OVERSEAS}`,
  },
  [`${RecruitmentType.INTERN}_${RecruitmentPlanType.DOMAIN}`]: {
    eventCategory: `${RECRUITMENT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${RECRUITMENT_CATEGORY}_${RecruitmentType.INTERN}_${RecruitmentPlanType.DOMAIN}`,
  },
};
const jobListPageEvent = {
  [matomoEventTypeMap.jobList.back]: {
    eventCategory: `${JOB_LIST_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${JOB_LIST_CATEGORY}_backNav`,
  },
  [matomoEventTypeMap.jobList.hotCity]: {
    eventCategory: `${JOB_LIST_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${JOB_LIST_CATEGORY}_HotCity`,
  },
};
const aboutPageEvent = {
  [matomoEventTypeMap.about.globalImpact.back]: {
    eventCategory: `${ABOUT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${ABOUT_CATEGORY}_GlobalImpact_backNav`,
  },
  [matomoEventTypeMap.about.graduates.back]: {
    eventCategory: `${ABOUT_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${ABOUT_CATEGORY}_Graduates_backNav`,
  },
};
const superXPageEvent = {
  [matomoEventTypeMap.superX.apply]: {
    eventCategory: `${SUPER_X_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${SUPER_X_CATEGORY}_${CTA_BANNER_EVENT_CATEGORY}_立即申请`,
  },
  [matomoEventTypeMap.superX.showMore]: {
    eventCategory: `${SUPER_X_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${SUPER_X_CATEGORY}_照片墙_展开`,
  },
  [matomoEventTypeMap.superX.close]: {
    eventCategory: `${SUPER_X_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${SUPER_X_CATEGORY}_照片墙_收起`,
  },
};
const applicationPageEvent = {
  [matomoEventTypeMap.application.back]: {
    eventCategory: `${APPLICATION_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${APPLICATION_CATEGORY}_backNav`,
  },
  [matomoEventTypeMap.application.applyNow]: {
    eventCategory: `${APPLICATION_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${APPLICATION_CATEGORY}_${CTA_BANNER_EVENT_CATEGORY}_立即投递`,
  },
  [matomoEventTypeMap.application.apply]: {
    eventCategory: `${APPLICATION_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${APPLICATION_CATEGORY}_申请此岗位`,
  },
  [matomoEventTypeMap.application.attachment]: {
    eventCategory: `${APPLICATION_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${APPLICATION_CATEGORY}_attachment`,
  },
  [matomoEventTypeMap.application.uploadAttachment]: {
    eventCategory: `${APPLICATION_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${APPLICATION_CATEGORY}_attachment_upload`,
  },
  [matomoEventTypeMap.application.cancelUploadAttachment]: {
    eventCategory: `${APPLICATION_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${APPLICATION_CATEGORY}_attachment_upload_undo`,
  },
};
const footerEvent = {
  [matomoEventTypeMap.footer.joinUs]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_岗位投递`,
  },
  [matomoEventTypeMap.footer.superX]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_SuperX`,
  },
  [matomoEventTypeMap.footer.training]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_培训发展`,
  },
  [matomoEventTypeMap.footer.notice]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_信息公告`,
  },
  [matomoEventTypeMap.footer.faq]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_答疑专区`,
  },
  [matomoEventTypeMap.footer.aboutUs]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_关于我们`,
  },
  [matomoEventTypeMap.footer.thoughtWorksWeb]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_官方网站`,
  },
  [matomoEventTypeMap.footer.thoughtWorksInsight]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_洞见`,
  },
  [matomoEventTypeMap.footer.thoughtWorksPolicy]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_隐私政策`,
  },
  [matomoEventTypeMap.footer.thoughtWorksCodeOfConduct]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_行为准则`,
  },
  [matomoEventTypeMap.footer.emailsTo]: {
    eventCategory: FOOTER_EVENT_CATEGORY,
    eventAction: 'Click',
    eventName: `${FOOTER_EVENT_CATEGORY}_邮件联系`,
  },
};
const successPageEvent = {
  [matomoEventTypeMap.success.myCenter]: {
    eventCategory: `${APPLICATION_SUCCESS_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${APPLICATION_SUCCESS_CATEGORY}_${MY_CENTER_CATEGORY}`,
  },
};
const accountPageEvent = {
  [matomoEventTypeMap.account.gradLink]: {
    eventCategory: `${PROFILE_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${PROFILE_CATEGORY}_校招岗位`,
  },
  [matomoEventTypeMap.account.internshipLink]: {
    eventCategory: `${PROFILE_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${PROFILE_CATEGORY}_实习生岗位`,
  },
};
const appliedWarningModalEvent = {
  [matomoEventTypeMap.appliedWarningModal.myCenter]: {
    eventCategory: `${APPLIED_WARNING_MODAL_CATEGORY}`,
    eventAction: 'Click',
    eventName: `${APPLIED_WARNING_MODAL_CATEGORY}_${MY_CENTER_CATEGORY}`,
  },
};

export const matomoEvent = {
  ...navEvent,
  ...wechatEvent,
  ...mainPageEvent,
  ...recruitmentPageEvent,
  ...jobListPageEvent,
  ...aboutPageEvent,
  ...superXPageEvent,
  ...applicationPageEvent,
  ...footerEvent,
  ...successPageEvent,
  ...accountPageEvent,
  ...appliedWarningModalEvent,
};
